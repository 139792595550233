/** @jsx jsx */
import { jsx, Link, Box, Heading, Paragraph, Badge } from "theme-ui"
import { graphql } from "gatsby"

import TopNavigation from "../components/TopNavigation"
import Layout from "../layout"

import pngLogo from "../images/amooto-logo.png"
import svgLogo from "../images/amooto-logo.svg"
import pdfLogo from "../images/amooto-logo.pdf"

function PressPage(props) {
  const { data } = props
  return (
    <Layout title="Amooto Press contact">
      <TopNavigation />
      <Heading mt={4} mb={3} as="h1">
        Press Contact
      </Heading>
      <Paragraph sx={{ maxWidth: 700 }}>
        If you're interested in writing a story about Amooto or have a question
        for our communications team, please email{" "}
        <Link href={`mailto:${data.site.siteMetadata.emailPress}`}>
          press@amooto.com
        </Link>
        .
      </Paragraph>
      <Box mt={4}>
        <Link
          href={svgLogo}
          download="amooto-logo"
          sx={{ textDecoration: "none", color: "text" }}
        >
          Amooto logo
        </Link>
        <Badge ml={2} variant="small">
          SVG
        </Badge>
      </Box>
      <Box mt={1}>
        <Link
          href={pngLogo}
          download="amooto-logo"
          sx={{ textDecoration: "none", color: "text" }}
        >
          Amooto logo
        </Link>
        <Badge ml={2} variant="small">
          PNG
        </Badge>
      </Box>
      <Box mt={1}>
        <Link
          href={pdfLogo}
          download="amooto-logo"
          sx={{ textDecoration: "none", color: "text" }}
        >
          Amooto logo
        </Link>
        <Badge ml={2} variant="small">
          PDF
        </Badge>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PressPageQuery {
    site {
      siteMetadata {
        emailPress
      }
    }
  }
`

export default PressPage
